<template>
  <div>
    <address-cascader 
      ref="cascader"
      :type="addrtype"
      :is-login="isLogin"
      :is-not-single-country="isNotSingleCountry"
      :language="language"
      :addrshow="addrshow"
      :disabled="disabled"
      :country-id="countryId"
      :country-value="countryValue"
      :state-value="state"
      :city-value="city"
      :district-value="district"
      :postcode-value="postcode"
      :country-list="countryList"
      :common="commonList"
      :state-list="stateList"
      :city-list="cityList"
      :district-list="districtList"
      :postcode-list="postcodeList"
      :has-state="keyShow.newState"
      :has-city="keyShow.newCity"
      :has-district="keyShow.district"
      :has-login="hasLogin"
      @login="toLogin"
      @select="onSelectUpper"
      @change="onTypeChange"
      @cancel="clickClose"
    />
  </div>
</template>

<script>
/**
 * 
 * 商品详情页，物流地址选择组件
 * 
 */
import address_cascader from './address_cascader.vue'
import { indexConfig } from './utils'
import schttp from 'public/src/services/schttp'
import { isLogin } from 'public/src/pages/common/utils/index.js'
const { lang } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
const { singleCountry } = indexConfig
const hideStates = ['113', '209', '97', '105'] // 需隐藏state的国家
const hideCity = ['212']

export default {
  name: 'AddressCascaderVue',
  components: {
    'address-cascader': address_cascader
  },
  props: {
    language: {
      type: Object,
      default () {
        return {}
      }
    },
    addrshow: {
      type: Boolean,
      default: false
    },
    hasLogin: {
      type: Boolean,
      default: true
    },
    // 是否仅选择国家维度，省份和城市就不选择了
    isOnlySelectCountry: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      addrtype: 0,
      countryList: [],
      commonList: [],
      disabled: [],
      isLogin: false,
      noSendCountry: false,

      countryId: '',
      countryValue: '',
      state: '',
      city: '',
      district: '',
      postcode: '',
      stateList: [],
      cityList: [],
      districtList: [],
      postcodeList: [],
      keyShow: {
        state: true,
        newState: false,
        city: true,
        newCity: false,
        district: false
      },
      addrCache: {
        _country: {},
        _countryObj: {},
        _state: {},
        _city: {},
        _district: {}
      },
      singleCountry,
      hideStates,
      hideCity
    }
  },
  computed: {
    isNotSingleCountry: function () {
      return !this.singleCountry[lang]
    }
  },
  beforeMount(){
    this.isLogin = isLogin()
  },
  methods: {
    getStateSelectedId (ctryid = this.countryId, state = this.state) {
      if (ctryid == 97) {
        // 为了暂时兼容app州省不隐藏，stateId写死
        return '984812'
      } else if (ctryid == 105) {
        return 'AddressAreaByType_city'
      } else {
        let parentId = ''
        if (this.addrCache._state[ctryid]) {
          for (let i = 0; i < this.addrCache._state[ctryid].length; i++) {
            const item = this.addrCache._state[ctryid][i]
            if (item.name == state || $.inArray(ctryid + '', this.hideStates) > -1) {
              parentId = item.id
              break
            }
          }
        }
        return parentId
      }
    },
    getCitySelectedId (ctryid = this.countryId, state = this.state, city = this.city) {
      let parentId = ''
      const cacheId = ctryid + '_' + this.getStateSelectedId(ctryid, state)
      if (this.addrCache._city[cacheId]) {
        for (let i = 0; i < this.addrCache._city[cacheId].length; i++) {
          const item = this.addrCache._city[cacheId][i]
          if (item.name == city || $.inArray(ctryid + '', this.hideCity) > -1) {
            parentId = item.id
            break
          }
        }
      }
      return parentId
    },
    getDistrictSelectedId (ctryid = this.countryId, state = this.state, city = this.city, district = this.district) {
      let parentId = ''
      const cacheId = ctryid + '_' + this.getCitySelectedId(ctryid, state, city)
      if (this.addrCache._district[cacheId]) {
        for (let i = 0; i < this.addrCache._district[cacheId].length; i++) {
          const item = this.addrCache._district[cacheId][i]
          if (item.name == district) {
            parentId = item.id
            break
          }
        }
      }
      return parentId
    },
    getData () {
      const stateId = this.keyShow.newState ? this.getStateSelectedId() : ''
      const cityId = this.keyShow.newCity ? this.getCitySelectedId() : ''
      const districtId = this.keyShow.district ? this.getDistrictSelectedId() : ''
      if (this.hideStates.indexOf(this.countryId) > -1 && this.countryId != '105') {
        this.state = ''
      }
      if (this.hideCity.indexOf(this.countryId) > -1) {
        this.city = ''
      }
      const addrData = {
        country_id: this.countryId || '',
        country_name: this.countryValue || '',
        state: this.state || '',
        city: this.city || '',
        district: this.district || '',
        stateId,
        cityId,
        districtId,
      }
      if (this.countryId == '105') {
        addrData.state = this.state
        addrData.stateId = this.stateId
      }
      return addrData
    },
    filterByCountry() {
      const ctryid = this.countryId + ''
      if (!ctryid) {
        this.keyShow.state = true
        this.keyShow.newState = false
        this.keyShow.city = true
        this.keyShow.newCity = false
        this.keyShow.district = false
      }
    },
    async getParentAddressAreaInfo({ currentType = 'city', currentId = '', currentName = '', needType = 'state' } = {}) {
      const data = {
        countryId: this.countryId,
        currentType,
        currentId,
        currentName,
        needType
      }
      const res = await schttp({
        url: '/api/productInfo/parentAddressAreaInfo/get',
        params: data,
      })
      return res?.info?.res || {}
    },
    clickClose: function () {
      this.$emit('cancel')
    },
    async updateUI (isFirst) {
      if (!isFirst && this.addrtype) {
        if (this.keyShow.district) {
          this.addrtype = 4
        } else if (this.keyShow.newCity) {
          this.addrtype = 3
        } else if (this.keyShow.newState) {
          this.addrtype = 2
        } else {
          this.addrtype = 1
        }
        return
      }
      const singleCountry = this.singleCountry[lang]
      if (singleCountry) {
        // 单国家站点
        this.countryId = singleCountry.id || ''
      }
      await this.bindCountryData()
      if (this.countryId) {
        await this.bindStateData()
        if ($.inArray(this.countryId + '', this.hideStates) > -1) {
          await this.bindCityData()
          if (this.cityList.length != 0){
            if (this.hideCity.indexOf(this.countryId) > -1) {
              this.citySelect(this.cityList[0])
            } else {
              this.addrtype = 3
            }
          }
        } else {
          this.addrtype = 2
        }
      } else {
        this.addrtype = 1
      }
      this.filterByCountry()
    },
    async bindCountryData () {
      this.addrCache._countryObj = {}
      if (this.singleCountry[lang]) {
        // 单国家站点
        this.countryValue = this.singleCountry[lang].country
        this.countryId = this.singleCountry[lang].id
        return
      } else {
        if (Object.keys(this.addrCache._country).length) {
          const _country = this.addrCache._country
          this.updataCountryList(_country)
          return
        } else {
          const countryUrl = '/api/user/addressbook/universalCountryList/get'
          const res = await schttp({
            url: countryUrl,
          })
          let _country = res.code == 0 ? res.info.res : {}
          this.addrCache._country = _country
          this.updataCountryList(_country)
        }
      }
    },
    updataCountryList (_country) {
      const allCountry = []
      const hotCountry = []
      if (_country.hot_country) {
        _country.hot_country.forEach((item) => {
          hotCountry.push({ value: item.id, label: item.country, noSendCountry: +item.is_shield === 1 })
        })
      }
      if (_country.item_country) {
        _country.item_country.forEach((item) => {
          allCountry.push({ value: item.id, label: item.country, noSendCountry: +item.is_shield === 1 })
          this.addrCache._countryObj[item.id] = item.country
        })
      }
      this.commonList = hotCountry
      this.countryList = allCountry
      this.countryValue = this.countryId ? this.addrCache._countryObj[this.countryId] : ''
    },
    async bindStateData () {
      // 根据国家id获取state信息
      const ctryid = this.countryId
      if (!this.addrCache._state[ctryid]) {
        const res = await schttp({
          url: '/api/productInfo/addressById/get',
          params: {
            parentId: ctryid,
            type: '2'
          },
        })
        if (res.code == 0) {
          const _state = res.info
          this.addrCache._state[ctryid] = _state // 缓存州
          this.updateStateList()
        }
      } else {
        this.updateStateList()
      }
    },
    updateStateList () {
      const ctryid = this.countryId
      const states = this.addrCache._state[ctryid]
      if (states && states.length) {
        if ($.inArray(ctryid + '', this.hideStates) > -1) {
          this.keyShow.newState = false
        } else {
          this.keyShow.newState = true
          this.keyShow.newCity = false
          this.keyShow.city = !($.inArray(ctryid + '', this.hideCity) > -1) // 泰国地址不显示city
        }
        this.keyShow.state = false
        this.keyShow.district = false
        this.state = ''
        this.stateList = states.map(item => {
          return { value: item.name, label: item.name, noSendCountry: +item.is_shield === 1 }
        })
        this.city = ''
        this.district = ''
      } else {
        this.keyShow.state = true
        this.keyShow.newState = false
        this.keyShow.city = true
        this.keyShow.newCity = false
        this.keyShow.district = false
        this.state = ''
      }
    },
    async bindCityData () {
      // 根据国家id、state获取city
      const ctryid = this.countryId
      let cacheId = ''
      let url = ''
      let params = {}
      if (ctryid == '105') {
        url = '/api/productInfo/countryAddressAreaByType/get'
        cacheId = `${ this.countryId }_AddressAreaByType_city`
        params = {
          ...params,
          countryId: ctryid,
          type: 'city'
        }
      } else {
        const state = this.state
        const parentId = this.getStateSelectedId(ctryid, state)
        if (!parentId) return
        cacheId = ctryid + '_' + parentId
        url = '/api/productInfo/addressById/get',
        params = {
          ...params,
          parentId,
          type: '3'
        }
      }
      if (!this.addrCache._city[cacheId]) {
        const res = await schttp({
          url: url,
          params,
        })
        if (res.code == 0) {
          this.addrCache._city[cacheId] = res?.info?.res || res?.info // 缓存州
          this.updataCityList(cacheId)
        }
      } else {
        this.updataCityList(cacheId)
      }
    },
    updataCityList (cacheId) {
      const ctryid = this.countryId
      const citys = this.addrCache._city[cacheId] ? this.addrCache._city[cacheId] : []
      if (citys.length) {
        this.cityList = citys.map(item => {
          return { value: item.name, label: item.name, noSendCountry: +item.is_shield === 1, id: item.id }
        })
        this.keyShow.city = false
        this.keyShow.newCity = !($.inArray(ctryid + '', this.hideCity) > -1)
        this.city = ''
        this.keyShow.district = false
        this.districtList = []
        this.district = ''
      } else {
        this.cityList = []
        this.keyShow.newCity && (this.city = '')
        this.keyShow.city = true
        this.keyShow.newCity = false
      }
    },
    async bindDistrictData () {
      const ctryid = this.countryId
      const state = this.state
      const city = this.city
      const parentId = this.getCitySelectedId(ctryid, state, city)
      if (!parentId) return
      const cacheId = ctryid + '_' + parentId
      if (!this.addrCache._district[cacheId]) {
        const res = await schttp({
          url: '/api/productInfo/addressById/get',
          params: {
            parentId,
            type: '4'
          },
        })
        if (res.code == 0) {
          this.addrCache._district[cacheId] = res.info // 缓存州
          this.updataDistrictList(cacheId)
        }
      } else {
        this.updataDistrictList(cacheId)
      }
    },
    updataDistrictList (cacheId) {
      if (this.addrCache._district?.[cacheId]?.length) {
        this.districtList = this.addrCache._district[cacheId].map(item => {
          return { value: item.name, label: item.name, noSendCountry: +item.is_shield === 1 }
        })
        this.keyShow.district = true
        this.district = ''
      } else {
        this.districtList = []
        this.keyShow.district = false
        this.district = ''
      }
    },
    async countrySelect (option) {
      this.countryValue = option.label
      this.countryId = option.value + ''
      this.filterByCountry()
      this.state = ''
      this.stateList = []
      this.cityList = []
      this.city = ''
      this.districtList = []
      this.district = ''
      if (this.isOnlySelectCountry) {
        this.selectUpperHide()
        return
      }
      await this.bindStateData()
      if (this.hideStates.indexOf(this.countryId) > -1) {
        await this.bindCityData()
        if (this.cityList.length == 0) {
          this.selectUpperHide()
        } else {
          if (this.hideCity.indexOf(this.countryId) > -1) {
            this.citySelect(this.cityList[0])
          } else {
            this.addrtype = 3
          }
        }
      } else {
        if (this.stateList.length == 0) {
          this.selectUpperHide()
        } else {
          this.addrtype = 2
        }
      }
    },
    async stateSelect (option) {
      this.state = option.value
      this.cityList = []
      this.city = ''
      this.districtList = []
      this.district = ''
      await this.bindCityData()
      if (this.cityList.length == 0) {
        this.selectUpperHide()
      } else {
        if (this.hideCity.indexOf(this.countryId) > -1) {
          this.citySelect(this.cityList[0])
        } else {
          this.addrtype = 3
        }
      }
    },
    async citySelect (option) {
      this.city = option.value
      this.districtList = []
      this.district = ''
      if (this.countryId == '105') {
        const { name, id } = await this.getParentAddressAreaInfo({ currentId: option?.id, currentName: this.city })
        this.state = name
        this.stateId = id
        await this.selectUpperHide()
      } else {
        await this.bindDistrictData()
        if (this.districtList.length == 0) {
          await this.selectUpperHide()
        } else {
          this.addrtype = 4
        }
      }
    },
    async districtSelect (option) {
      this.district = option.value
      await this.selectUpperHide()
    },
    onSelectUpper (data) {
      this.noSendCountry = data.noSendCountry
      if (this.addrtype == 1) {
        this.countrySelect(data)
      } else if (this.addrtype == 2 && this.disabled.indexOf('state') == -1) {
        this.stateSelect(data)
      } else if (this.addrtype == 3) {
        this.citySelect(data)
      } else if (this.addrtype == 4) {
        this.districtSelect(data)
      }
    },
    onTypeChange (data) {
      this.addrtype = data
    },
    selectUpperHide () {
      return new Promise((resolve) => {
        let curData = this.getData()
        setTimeout(() => {
          const data = {
            city: this.city,
            state_province: this.state,
            country_id: this.countryId,
            district: this.district,
            country: this.countryValue,
            address_id: '',
            noSendCountry: this.noSendCountry,
            state_id: curData.stateId,
            city_id: curData.cityId,
            district_id: curData.districtId,
          }

          this.$emit('otheraddresscallback', data)
          this.clickClose()
          resolve()
        }, 20)
      })
    },
    clearData() {
      if (!this.singleCountry[lang]) {
        this.countryValue = ''
        this.countryId = ''
      }
      this.state = ''
      this.stateList = []
      this.cityList = []
      this.city = ''
      this.districtList = []
      this.district = ''
      this.keyShow.newState = false
      this.keyShow.newCity = false
      this.keyShow.district = false
    },
    toLogin: function () {
      SHEIN_LOGIN && SHEIN_LOGIN.show({ show: true, from: 'other', cb: this.handleLoginEvent })
    },
    handleLoginEvent: function () {
      this.isLogin = true
      this.$emit('loginsuccess', true)
    }
  }
}
</script>
