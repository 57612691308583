<template>
  <s-drawer
    class="c-address-upper-drawer"
    type="full"
    :visible.sync="show"
    :direction="type == 5 ? 'btt' : 'rtl'"
    append-to-body
    no-header
    @close="show = false"
  >
    <div
      ref="upperWrap"
      class="c-upper-wrap"
      @click.stop=""
    >
      <header
        ref="upperHeader"
        class="common-address-header"
        tabindex="0"
      >
        <section class="address-header-inner">
          <span
            v-enterkey
            class="header-close"
            tabindex="0"
            :aria-label="language.SHEIN_KEY_PWA_15567"
            @click="clickCancel"
          > <i class="iconfont icon-close"></i> </span>
          <p
            ref="upperHeaderTitle"
            class="header-title"
            tabindex="0"
            v-html="headerTitle"
          ></p>
        </section>
        <section
          v-if="!isLogin && showLoginTip"
          class="address-header-login-tip"
        >
          <div class="login-tip-content">
            <div
              v-enterkey
              class="close-wrap"
              tabindex="0"
              :aria-label="language.SHEIN_KEY_PWA_15567"
              @click="showLoginTip = false"
            >
              <i class="iconfont icon-close"></i>
            </div>
            <span>{{ language.SHEIN_KEY_PWA_16452 }}</span>
          </div>
          <button
            da-event-click="1-6-4-23"
            @click="toLogin"
          >
            {{ language.SHEIN_KEY_PWA_15578 }}
          </button>
        </section>
        <section
          v-if="(common.length || dataList.length) && type < 5"
          class="address-header-tab"
        >
          <span
            v-if="isNotSingleCountry"
            v-enterkey
            class="j-tab-item"
            tabindex="0"
            :class="{ 'has-value': countrySelected, selected: type == 1 }"
            @click="tabnavChange(1)"
          >{{ countryValue || language.SHEIN_KEY_PWA_16025 + ' ' + language.SHEIN_KEY_PWA_16020 }}</span>
          <span
            v-if="hasState"
            v-enterkey
            class="j-tab-item"
            tabindex="0"
            :class="{ 'has-value': stateSelected, selected: type == 2 }"
            @click="tabnavChange(2)"
          >{{ stateValue || language.SHEIN_KEY_PWA_15804 }}</span>
          <span
            v-if="hasCity"
            v-enterkey
            class="j-tab-item"
            tabindex="0"
            :class="{ 'has-value': citySelected, selected: type == 3 }"
            @click="tabnavChange(3)"
          >{{ cityValue || language.SHEIN_KEY_PWA_15805 }}</span>
          <span
            v-if="hasDistrict"
            v-enterkey
            class="j-tab-item"
            tabindex="0"
            :class="{ 'has-value': districtSelected, selected: type == 4 }"
            @click="tabnavChange(4)"
          >{{ districtValue || language.SHEIN_KEY_PWA_15806 }}</span>
        </section>
        <section
          v-if="hasSearch "
          class="address-header-search"
        >
          <div class="search-input">
            <i class="iconfont icon-search01"></i><input
              ref="searchInput"
              v-model="searchValue"
              type="text"
              :placeholder="searchPlaceholder"
              @focus="searchFocus = true"
              @blur="searchBlur"
            /> <i
              v-if="searchValue && searchFocus"
              v-enterkey
              class="iconfont icon-clear1"
              :aria-label="language.SHEIN_KEY_PWA_15666"
              tabindex="0"
              @click.stop.prevent="clearSearchInput"
            ></i>
          </div>
          <span
            v-show="searchFocus"
            v-enterkey
            class="search-cancel"
            tabindex="0"
            :aria-label="language.SHEIN_KEY_PWA_15254"
            @click="searchFocus = false"
            v-html="language.SHEIN_KEY_PWA_15106"
          ></span>
        </section>
      </header>
      <div
        v-if="(common.length || dataList.length)"
        ref="upperList"
        class="c-upper-list"
        :class="{ 'has-search-login': hasSearch && (!isLogin) && showLoginTip, 'has-search': hasSearch && !(!isLogin && showLoginTip), 'has-login': !hasSearch && (!isLogin) && showLoginTip, }"
      >
        <template v-if="type == 5">
          <div class="addr-upper-item addr-area-item">
            <ul class="upper-list">
              <li
                v-for="(item, index) in dataList"
                :key="index"
                v-enterkey
                tabindex="0"
                :class="{ selected: item.label == checkedValue }"
                @click="selectTarget(item)"
              >
                {{ item.label }} <i
                  v-if="item.label == checkedValue"
                  class="iconfont icon-normal-checked"
                ></i>
              </li>
            </ul>
          </div>
        </template>

        <template v-else>
          <div
            v-if="hasCommon"
            ref="j-letter-common"
            class="addr-upper-item"
          >
            <h3
              v-if="hasCommonTitle"
              tabindex="0"
              class="group-title"
              :class="{ 'country-group': type == 1 }"
              v-html="language.SHEIN_KEY_PWA_16009"
            ></h3>
            <ul class="upper-list">
              <li
                v-for="(item, index) in sortCommon"
                :key="index"
                v-enterkey
                tabindex="0"
                :class="{ selected: item.label == checkedValue }"
                @click="selectTarget(item)"
              >
                {{ item.label }} <i
                  v-if="item.label == checkedValue"
                  class="iconfont icon-normal-checked"
                ></i>
              </li>
            </ul>
          </div>
          <div
            v-for="(item1, first, index1) in classificationLists"
            :key="index1"
            :ref="'j-letter-' + index1"
            class="addr-upper-item"
            :class="['j-letter-' + index1, {'addr-area-item': type != 1}]"
          >
            <h3
              tabindex="0"
              class="group-title"
            >
              {{ first }}
            </h3>
            <ul class="upper-list">
              <template v-for="(item2, index2) in item1">
                <li
                  :key="index2"
                  :ref="'j-item-' + item2.value"
                  v-enterkey
                  tabindex="0"
                  :class="{ selected: item2.label == checkedValue, disabled: itemDisabled }"
                  @click="selectTarget(item2)"
                >
                  {{ item2.label }} <i
                    v-if="item2.label == checkedValue"
                    class="iconfont icon-normal-checked"
                  ></i>
                </li>
              </template>
            </ul>
          </div>
        </template>
      </div>
      <div
        v-else
        class="c-upper-list"
        tabindex="0"
      >
        <p class="empty-tips">
          {{ language.SHEIN_KEY_PWA_16847 }}
        </p>
      </div>

      <div
        v-if="type != 5"
        class="c-upper-silder"
        @touchstart.stop.prevent="onShortcutTouchStart"
        @touchmove.stop.prevent="onShortcutTouchMove"
        @touchend.stop.prevent="onShortcutTouchEnd"
      >
        <ul class="addr-shortcut-list">
          <li
            v-if="hasCommon && hasCommonTitle"
            ref="j-shortcut-common"
            :data-index="-1"
          >
            <span
              class="shortcut-item"
              :class="{ selected: shortcutIndex == -1 && shortcutTouch }"
              :data-index="-1"
            >✤</span>
          </li>
          <li
            v-for="(item, index) in shortcutList"
            :key="index"
            :ref="'j-shortcut-' + index"
            :data-index="index"
          >
            <span
              class="shortcut-item"
              :class="{ selected: index == shortcutIndex && shortcutTouch }"
              :data-index="index"
            >{{ item }}</span>
          </li>
        </ul>
      </div>
      <div
        v-show="shortcutTouch"
        ref="shortcutSelected"
        class="shortcut-selected"
        tabindex="0"
      >
        <img
          v-if="shortcutTouch"
          :src="lang.public_cdn + '/pwa_dist/images/combined_shape-6254232b71.png'"
          :alt="language.SHEIN_KEY_PWA_14974"
        /> <span class="name">{{ shortcutValue }}</span>
      </div>
    </div>
  </s-drawer>
</template>

<script>
/**
 *
 * 商品详情页，物流地址选择组件
 *
 */
import Vue from 'vue'
import { Drawer } from '@shein/sui-mobile'
Vue.use(Drawer)

const searchConfig = {
  searchCountry: ['226', '38', '74', '82', '198', '106', '150', '13', '206', '105', '137', '30', '233', '100']
}
const ANCHOR_HEIGHT = 18
const { lang, PUBLIC_CDN } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
export default {
  name: 'AddressCascader',
  props: {
    type: {
      type: Number,
      default: 0
    },
    addrshow: {
      type: Boolean,
      default: false
    },
    countryList: {
      type: Array,
      default () {
        return []
      }
    },
    common: {
      type: Array,
      default () {
        return []
      }
    },
    stateList: {
      type: Array,
      default () {
        return []
      }
    },
    cityList: {
      type: Array,
      default () {
        return []
      }
    },
    districtList: {
      type: Array,
      default () {
        return []
      }
    },
    postcodeList: {
      type: Array,
      default () {
        return []
      }
    },
    countryId: {
      type: String,
      default: ''
    },
    countryValue: {
      type: String,
      default: ''
    },
    stateValue: {
      type: String,
      default: ''
    },
    cityValue: {
      type: String,
      default: ''
    },
    districtValue: {
      type: String,
      default: ''
    },
    postcodeValue: {
      type: String,
      default: ''
    },
    hasState: {
      type: Boolean,
      default: false
    },
    hasCity: {
      type: Boolean,
      default: false
    },
    hasDistrict: {
      type: Boolean,
      default: false
    },
    isNotSingleCountry: {
      type: Boolean,
      default: true
    },
    isLogin: {
      type: Boolean,
      default: false
    },
    disabled: {
      // 控制省份是否置灰
      type: Array,
      default () {
        return []
      }
    },
    upperLang: {
      type: Object,
      default () {
        return {}
      }
    },
    storeList: {
      // 台湾店配
      type: Array,
      default () {
        return []
      }
    },
    language: {
      type: Object,
      default () {
        return {}
      }
    },
    hasLogin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      lang: {
        lang: lang,
        public_cdn: PUBLIC_CDN
      },
      shortcutIndex: 0,
      shortcutTouch: false, // shortcut是否获取焦点
      searchValue: '',
      searchFocus: false,
      clickClearFlag: false,
      showLoginTip: true,
      show: false
    }
  },
  computed: {
    hasSearch () {
      return (searchConfig.searchCountry.indexOf(this.countryId) > -1 && (this.type == 2 || this.type == 3 || this.type == 4)) || (this.type == 1 && this.isNotSingleCountry)
    },
    searchPlaceholder () {
      if (this.type == 1) {
        return this.language.SHEIN_KEY_PWA_16020
      } else if (this.type == 2) {
        return this.language.SHEIN_KEY_PWA_15561
      } else if (this.type == 3) {
        return this.language.SHEIN_KEY_PWA_15075
      } else if (this.type == 4) {
        return this.language.SHEIN_KEY_PWA_15078
      } else {
        return ''
      }
    },
    hasCommonTitle () {
      return ['uk'].indexOf(this.lang.lang) == -1
    },
    headerTitle () {
      return this.language.SHEIN_KEY_PWA_16266
    },
    classificationLists() {
      let obj = {}
      for (let i = 0; i < this.dataList.length; i++) {
        const item = this.dataList[i]
        const first = item.label.substr(0, 1)
        obj[first] = obj[first] ? [...obj[first], item] : [item]
      }
      return obj
    },
    dataList () {
      let list = []
      if (this.type == 1) {
        list = this.countryList
      } else if (this.type == 2) {
        list = this.stateList
      } else if (this.type == 3) {
        list = this.cityList
      } else if (this.type == 4) {
        list = this.districtList
      } else if (this.type == 5) {
        list = this.postcodeList
      } else if (this.type == 6) {
        list = this.storeList
      }
      list.sort((prev, next) => prev.label.localeCompare(next.label))
      return list
    },
    shortcutList () {
      return Object.keys(this.classificationLists)
    },
    shortcutValue () {
      return this.shortcutIndex == -1 ? '✤' : this.shortcutList[this.shortcutIndex]
    },
    valueSelected () {
      let array = []
      if (this.type == 1) {
        array = this.common.concat(this.countryList)
      } else if (this.type == 5) {
        array = this.postcodeList
      }
      const results = array.map(item => {
        return item.label == this.checkedValue
      })
      return !!results.length
    },
    countrySelected () {
      const results = this.countryList.map(item => {
        return item.label == this.countryValue
      })
      return !!results.length
    },
    stateSelected () {
      const results = this.stateList.map(item => {
        return item.label == this.stateValue
      })
      return !!results.length
    },
    citySelected () {
      const results = this.cityList.map(item => {
        return item.label == this.cityValue
      })
      return !!results.length
    },
    districtSelected () {
      const results = this.districtList.map(item => {
        return item.label == this.districtValue
      })
      return !!results.length
    },
    checkedValue () {
      let value = ''
      if (this.type == 1) {
        value = this.countryValue
      } else if (this.type == 2) {
        value = this.stateValue
      } else if (this.type == 3) {
        value = this.cityValue
      } else if (this.type == 4) {
        value = this.districtValue
      }
      return value
    },
    itemDisabled () {
      return this.type == 2 && this.disabled.indexOf('state') > -1
    },
    hasCommon () {
      return this.type == 1 && this.common.length
    },
    sortCommon () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.common.sort((prev, next) => prev.label.localeCompare(next.label))
    }
  },
  watch: {
    upperLang (value) {
      this.lang = value
    },
    addrshow (value) {
      if (value) {
        document.body.className += ' modal-open'
      } else {
        document.body.className = document.body.className.replace(/\s?modal-open/, '')
      }
      this.show = !!value
      this.searchValue = ''
    },
    searchValue (value) {
      if (!value) return
      for (let i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].label.substr(0, value.length).toUpperCase() == value.toUpperCase()) {
          const id = this.dataList[i].value
          this.$refs.upperList.scrollTop = this.$refs['j-item-' + id][0].offsetTop - this.$refs.upperHeader.clientHeight
          break
        }
      }
    }
  },
  created () {
    this.touch = {}
    if (this.hasLogin == false) {
      this.showLoginTip = false
    }
  },
  methods: {
    toLogin: function () {
      this.$emit('login')
    },
    onShortcutTouchStart (e) {
      const index = e.target.getAttribute('data-index')
      const firstTouch = e.touches[0]
      this.touch.y1 = firstTouch.pageY
      this.touch.anchorIndex = index
      this.shortcutTouch = true
      this._scrollTo(index)
    },
    onShortcutTouchMove (e) {
      const firstTouch = e.touches[0]
      this.touch.y2 = firstTouch.pageY
      const delta = ((this.touch.y2 - this.touch.y1) / ANCHOR_HEIGHT) | 0
      const index = parseInt(this.touch.anchorIndex) + delta
      const minLength = this.hasCommon ? -1 : 0
      if (index >= minLength && index < this.shortcutList.length) {
        this._scrollTo(index)
      }
    },
    onShortcutTouchEnd () {
      this.shortcutTouch = false
    },
    clickCancel () {
      this.$emit('cancel')
    },
    selectTarget (data) {
      this.searchValue = ''
      this.$emit('select', data)
    },
    tabnavChange (type) {
      this.searchValue = ''
      this.$emit('change', type)
    },
    searchBlur () {
      setTimeout(() => {
        !this.clickClearFlag && (this.searchFocus = false)
      }, 20)
    },
    clearSearchInput () {
      this.clickClearFlag = true
      this.searchValue = ''
      setTimeout(() => {
        this.$refs.searchInput.focus()
        this.clickClearFlag = false
      }, 30)
    },
    _scrollTo (index) {
      this.shortcutIndex = index
      const letterItem = index == -1 ? this.$refs['j-letter-common'] : this.$refs['j-letter-' + index][0]
      const shortcutItem = index == -1 ? this.$refs['j-shortcut-common'] : this.$refs['j-shortcut-' + index][0]
      this.$refs.upperList.scrollTop = letterItem.offsetTop - this.$refs.upperHeader.clientHeight
      this.$refs.shortcutSelected.style.top = shortcutItem.getBoundingClientRect().top - 3 + 'px'
    }
  }
}
</script>

<style lang="less" scoped>
/* stylelint-disable */
.j-goods-detail-address-upper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    transition: all .5s ease-in-out;
    z-index: 201;
    &.mshe-z-bottom-transform2.upper-bottom-transform2 {
        transform: translate3d(0,130%,999px);
    }
}
.j-goods-detail-address-upper,
.c-address-upper-drawer {
      .c-upper-wrap {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background: #fff;
        border-radius: .53rem .53rem 0 0;
        .common-address-header {
            position: absolute;
            top: 0;
            width: 100%;
            background: #fff;
            z-index: 102;
            .zindex-translatez(999,translate3d(0,0,999px));
            .address-header-inner {
                .flexbox;
                .align-center;
                height: 1.2rem;
                .header-title {
                    text-align: center;
                    width: 100%;
                    .font-dpr(34px);
                    font-weight: bold;
                    color: #333333;
                }
                .header-close {
                    position: absolute;
                    float: left;
                    padding: .2rem .4rem;
                    .icon-close {
                        .font-dpr(32px);
                    }
                }
            }
            .address-header-tab {
                .flexbox;
                .align-center;
                height: 1.17333rem;
                white-space: nowrap;
                overflow-x: scroll;
                width: 100%;
                padding: 0.3467rem .32rem .3733rem;
                border-bottom: 1px solid #e5e5e5;
                &::-webkit-scrollbar {
                    width: 0px;
                    height: 0px;
                }
                .j-tab-item {
                    color: #666666;
                    .font-dpr(28px);
                    height: 1.16666rem;
                    line-height: 1.16666rem;
                    font-weight: bold;
                    .margin-r(.53333rem);
                    display: inline-block;
                    &.selected {
                        color: #222;
                        border-bottom: 2px solid #222;
                    }
                }
            }
            .address-header-search {
                position: relative;
                height: 1.49333rem;
                padding: 0 .32rem;
                .flexbox;
                width: 100%;
                box-sizing: border-box;
                border-bottom: 1px solid #e5e5e5;
                .padding-r(0.6rem);
                .search-input {
                    position: relative;
                    flex: 1;
                    .icon-search01 {
                        position: absolute;
                        .left(.26666rem);
                        top: .42333rem;
                        .font-dpr(32px);
                        z-index: 1000;
                    }
                    .icon-clear1 {
                        position: absolute;
                        .right(.26666rem);
                        top: .42333rem;
                        .font-dpr(32px);
                        z-index: 1000;
                        color: #ccc;
                    }
                    input {
                        width: 100%;
                        height: .853333rem;
                        margin: .32rem 0;
                        border: none;
                        background: #f6f6f6;
                        color: #222;
                        padding: 0 .90666rem;
                        box-sizing: border-box;
                        &::-webkit-input-placeholder {      /* WebKit browsers*/
                            color: #999!important;
                            opacity: 1!important;
                        }
                        &::-moz-placeholder {            /* Mozilla Firefox 19+ */
                            color: #999!important;
                            opacity: 1!important;
                        }
                        &:-moz-placeholder {              /* Mozilla Firefox 4 to 18 */
                            color: #999!important;
                            opacity: 1!important;
                        }
                        &:-ms-input-placeholder {              /* Internet Explorer 10+*/
                            color: #999!important;
                            opacity: 1!important;
                        }
                    }
                }
                .search-cancel {
                    .font-dpr(28px);
                    color: #222;
                    text-transform : capitalize;
                    .margin-l(.32rem);
                    padding-top: .48rem;
                }
            }
            .address-header-login-tip{
                height: 1.08rem;
                background-color: #FCF0D4;
                line-height: 1.08rem;
                padding: 0 .32rem;
                position: relative;
                .flexbox();
                .align-center();
                .space-between();
                .login-tip-content{
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .close-wrap{
                        display: inline-block;
                        width: 1rem;
                        position: absolute;
                        .left(0);
                        .icon-close{
                            .font-dpr(24px);
                            color: #999999;
                            .padding-l(.32rem);
                        }
                    }
                    span{
                        .font-dpr(24px);
                        color: #222;
                        .padding-l(.64rem);
                    }
                }

                button{
                    height: .53333rem;
                    .font-dpr(22px);
                    color: #222;
                    border: 1px solid #333333;
                    background-color: #fcf0d4;
                    padding: 0 .2133rem;
                }
            }
        }
        .c-upper-list {
            padding: 2.34666rem 0 1rem;
            height: 100%;
            overflow-y: scroll;
            box-sizing: border-box;
            &.has-search {
                padding: 3.86rem 0 1rem;
            }
            &.has-login {
                padding: 3.45rem 0 1rem;
            }
            &.has-search-login {
                padding: 4.9rem 0 1rem;
            }
            .empty-tips {
                text-align: center;
            }
            .addr-upper-item {
                .group-title {
                    .font-dpr(28px);
                    background: #F6F6F6;
                    padding: .03rem .32rem;
                    font-weight: bold;
                    &.country-group {
                        background: #fff;
                        padding-top: .32rem;
                    }
                }
                .upper-list {
                    padding: 0 .32rem  .32rem;
                    li {
                        position: relative;
                        .font-dpr(28px);
                        padding: .32rem 0;
                        border-bottom: 1px solid #e5e5e5;
                        color: #333;
                        .icon-normal-checked {
                            position: absolute;
                            .font-dpr(36px);
                            .right(0.32rem);
                            .left(auto);
                            font-weight: normal;
                            top: .2rem;
                            color: @color_brand;
                        }
                        &.disabled {
                            color: #ccc;
                        }
                        &.selected {
                            font-weight: bold;
                        }
                    }
                }
                &.addr-area-item {
                    .upper-list {
                        li {
                            padding: .32rem 0;
                            .margin-r(.18rem);
                            .icon-normal-checked {
                                position: absolute;
                                .font-dpr(36px);
                                .right(0.32rem);
                                font-weight: normal;
                                top: .2rem;
                            }
                        }
                    }
                }
            }
        }
        .c-upper-silder {
            position: absolute;
            top: 50%;
            .right(0);
            z-index: 2999;
            transform: translate3d(0,-50%,2999px);
            overflow-y: hidden;
            text-align: center;
            font-size: 11px;
            background-color: #fff;
            .addr-shortcut-list {
                li {
                    .padding-l(.08rem);
                    .padding-r(.08rem);
                    color: #9b9b9b;
                    .shortcut-item {
                        display: inline-block;
                        height: .42666rem;
                        width: .42666rem;
                        line-height: .42666rem;
                        .font-dpr(26px);
                        &.selected {
                            background: #222;
                            border-radius: 50%;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .shortcut-selected {
            position: fixed;
            .font-dpr(40px);
            color: #fff;
            .right(.53333rem);
            width: .90666rem;
            height: .64rem;
            line-height: .64rem;
            .name {
                position: relative;
                .padding-l(.16rem);
            }
            img {
                position: absolute;
                width: 100%;
            }
        }
    }
}

/* stylelint-enable */
</style>
